import {useState, createContext, useEffect} from 'react'

export const TabsContext = createContext(false)

export const TabsProvider = ({children}) => {
  const [userId, setUserId] = useState()
  const [activeTab, setActiveTab] = useState(() => {
    return localStorage.getItem('activeTab') || 'home'
  })
  const [currentView, setCurrentView] = useState('job-posts')
  const [unreadChats, setUnreadChats] = useState()

  useEffect(() => {
    localStorage?.setItem('activeTab', activeTab)
  }, [activeTab])

  const toggleActiveTabToHome = () => {
    setActiveTab('home')
  }
  const toggleActiveTabToBrowseJobs = () => {
    setActiveTab('browse-jobs')
  }
  const toggleActiveTabToAppliedJobs = () => {
    setActiveTab('applied-jobs')
  }
  const toggleActiveTabToSavedJobs = () => {
    setActiveTab('saved-jobs')
  }
  const toggleActiveTabToJobPosts = () => {
    setActiveTab('job-posts')
  }
  const toggleActiveTabToFavoriteApplicants = () => {
    setActiveTab('favorite-applicants')
  }
  const toggleActiveTabToSettings = () => {
    setActiveTab('settings')
  }
  const toggleViewToJobPosts = () => {
    setCurrentView('job-posts')
  }
  const toggleViewToJobPost = () => {
    setCurrentView('job-post')
  }
  const toggleViewToCreateJobPost = () => {
    setCurrentView('create-job-post')
  }
  const toggleViewToEditJobPost = () => {
    setCurrentView('edit-job-post')
  }

  const toggleActiveTabToProfiles = () => {
    setActiveTab('profiles')
  }

  return (
    <TabsContext.Provider
      value={{
        activeTab,
        toggleActiveTabToHome,
        toggleActiveTabToBrowseJobs,
        toggleActiveTabToAppliedJobs,
        toggleActiveTabToSavedJobs,
        toggleActiveTabToJobPosts,
        toggleActiveTabToFavoriteApplicants,
        toggleActiveTabToSettings,
        toggleViewToJobPosts,
        toggleViewToJobPost,
        toggleViewToCreateJobPost,
        toggleViewToEditJobPost,
        toggleActiveTabToProfiles,
        currentView,
        setCurrentView,
        setUserId,
        userId,
        unreadChats,
        setUnreadChats,
      }}
    >
      {children}
    </TabsContext.Provider>
  )
}
